import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="predefined-position"
export default class extends Controller {
  static targets = [ "image", "printablePosition", "selectedPosition", "scale", "offsetLeft", "offsetTop", "calculatedSize"];


  connect() {
    this.updatePosition();
  }

  updatePosition() {
    
    if (!this.hasImageTarget || !this.hasPrintablePositionTarget)
    {
      return;
    }


    var image = this.imageTarget;
    var printable_position = this.printablePositionTarget;
    



    printable_position.style.width = image.dataset.percentWidth + "%";
    

    var printable_position_calucalation_height = 0;
    var offset_print_position_top_pixel = 0;
    
    if (image.dataset.useAbsoluteHeight == "1")
    {
      
      printable_position_calucalation_height = Math.round(printable_position.offsetWidth * image.dataset.direktdruckScaleFactor);
      offset_print_position_top_pixel = Math.round(printable_position_calucalation_height * (printable_position.dataset.direktdruckNotUseSpaceFromTopPercent / 100.0));

      printable_position.style.height = Math.round(printable_position.offsetWidth * image.dataset.direktdruckScaleFactor * (printable_position.dataset.direktdruckOnlyUsePercentOfTop / 100.0) - offset_print_position_top_pixel )  + "px";
      
    }
    else
    {

      printable_position.style.height = image.dataset.percentHeight + "%";
      printable_position_calucalation_height = printable_position.offsetHeight;
    }
    
    
    var top = image.offsetHeight * (image.dataset.percentOffsetTop / 100);
    top = top - (printable_position_calucalation_height / 2) + offset_print_position_top_pixel;

    if (top < 0 )
    {
      top = 0;
    }
    printable_position.style.top = top + "px";


    var left = image.offsetWidth * (image.dataset.percentOffsetLeft / 100);
    left = left - (printable_position.offsetWidth / 2);

    if (left < 0 )
    {
      left = 0;
    }
    printable_position.style.left = left + "px";


    var motiv_width = (printable_position.offsetWidth * (this.scaleTarget.value / 100));
    var motiv_height = motiv_width;


    if (motiv_height > printable_position.offsetHeight)
    {
      motiv_height = printable_position.offsetHeight;
    }


    this.selectedPositionTarget.style.width = motiv_width + "px";
    this.selectedPositionTarget.style.height = motiv_height + "px";
    

    

    var max_height = printable_position.offsetHeight - motiv_height;
    var motiv_top = max_height * (this.offsetTopTarget.value / 100);
    this.selectedPositionTarget.style.top = motiv_top + "px";

    var max_width = printable_position.offsetWidth - motiv_width;
    var motiv_left = max_width * (this.offsetLeftTarget.value / 100);
    this.selectedPositionTarget.style.left = motiv_left + "px";



    var cm_width = this.imageTarget.dataset.cmWidth;
    var cm_height = this.imageTarget.dataset.cmHeight;

    var motiv_width_cm = cm_width * motiv_width / printable_position.offsetWidth;
    var motiv_height_cm = cm_height * motiv_height / printable_position.offsetHeight;

    var motiv_offset_left_cm =  cm_width * (motiv_left / printable_position.offsetWidth);
    var motiv_offset_right_cm =  cm_width * ((max_width - motiv_left) / printable_position.offsetWidth);

    var motiv_offset_top_cm =  cm_height * (motiv_top / printable_position.offsetHeight);
    var motiv_offset_bottom_cm =  cm_height * ((max_height - motiv_top) / printable_position.offsetHeight);
    
    
    motiv_width_cm = Math.round(motiv_width_cm * 100 ) / 100;
    motiv_height_cm = Math.round(motiv_height_cm * 100 ) / 100;

    motiv_offset_left_cm = Math.round(motiv_offset_left_cm * 100 ) / 100;
    motiv_offset_right_cm = Math.round(motiv_offset_right_cm * 100 ) / 100;
    motiv_offset_bottom_cm = Math.round(motiv_offset_bottom_cm * 100 ) / 100;
    motiv_offset_top_cm = Math.round(motiv_offset_top_cm * 100 ) / 100;


    if (isNaN(motiv_height_cm))
    {
      motiv_height_cm = 0;
    }

    this.calculatedSizeTarget.innerHTML = "Größe: <b>" + motiv_width_cm + "</b> x <b>" + motiv_height_cm + "</b> cm <br> Abstand O, U: " + motiv_offset_top_cm + ", " + motiv_offset_bottom_cm + " cm<br> Abstand: L, R: " + motiv_offset_left_cm + ", " + motiv_offset_right_cm + " cm";


  }
}
