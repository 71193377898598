import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="motiv-own-price"
export default class extends Controller {

  static targets = [ "price", "provision" ]

  connect() {
    this.updatePrice();
  }

  updatePrice()
  {
    this.priceTargets.forEach((price) => {

      var show_price = parseFloat(price.dataset.price);

      if (this.hasProvisionTarget) {

        var provisionTarget = parseFloat(this.provisionTarget.value);

        if (isNaN(provisionTarget)) {
          provisionTarget = 0;
        }

        if (provisionTarget < 0) {
          provisionTarget = 0;
        }

        if (provisionTarget > 30) {
          provisionTarget = 30;
          this.provisionTarget.value = 30;
        }

        show_price = show_price + provisionTarget;
      }

      
      show_price = show_price.toFixed(2);
      
      price.innerHTML = new Intl.NumberFormat().format(show_price) + " " + price.dataset.currency;
    });
  }

}
