import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-modal"
export default class extends Controller {
  static targets = ["image", "modal", "title"]



  connect() {
  }

  show(event) {
    event.preventDefault()
    const myModal = new bootstrap.Modal(this.modalTarget, {})
    
    this.imageTarget.src = event.target.src;
    this.titleTarget.innerHTML = event.currentTarget.dataset.title;
    
    myModal.show();
  }
}
