import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="motiv-position-copy"
export default class extends Controller {

  static targets = [ "master", "slave" ]

  connect() {
    this.slaveTarget.value = this.masterTarget.value;
  }

  copyData(event) {
    if (event.target == this.masterTarget) {
      this.slaveTarget.value = this.masterTarget.value
    }

    if (event.target == this.slaveTarget) {
      this.masterTarget.value = this.slaveTarget.value
    }
  }
}
