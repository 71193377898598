
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "imageTarget", "selectable", "link"]
	static values = { preselected: String }
	connect() {

		var clicked = false;
		if (this.preselectedValue) 
		{
			this.selectableTargets.forEach(element => {
				console.log(element.dataset.idForSelector, this.preselectedValue);
				if (element.dataset.idForSelector == this.preselectedValue) {
					element.click();
					clicked = true;
				}
			});
		}

		if (!clicked) 
		{
			this.selectableTarget.click();
		};
	}

  selectImage(event) {
		
    
		this.imageTargetTarget.src = event.target.getAttribute('src');
		this.linkTarget.href = event.target.dataset.link;

		this.selectableTargets.forEach(element => {
			element.classList.remove("border-primary")	
		});
		
		event.target.classList.add("border-primary");
		

		console.log("selectImage");
  }
}