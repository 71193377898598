import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="kleinunternehmer-modal"
export default class extends Controller {

  static targets = [ "modal" ]

  connect() {
  }

  open(event) {
    
    if (event.currentTarget.checked) 
    {
      const myModal = new bootstrap.Modal(this.modalTarget, {})
      myModal.show();
    }
  }
}
