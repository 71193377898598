import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "countdownFields", "localeTimezone", "startDate", "endDate", "utcStartDate", "utcEndDate"];
  static values = {
    countdown: Number
  }

  connect() {
    this.toggleCountdownFields();
    this.updateUTCHints();
  }

  updateUTCHints() {
    // Get the timezone and offset of the user
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const offset = new Date().getTimezoneOffset() / -60;

    // Update the timezone info text
    this.localeTimezoneTarget.textContent = `${timeZone} (UTC${offset >= 0 ? '+' : ''}${offset})`;

    // Date format options
    const dateFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC'
    };
  
    // Update UTC start date
    if (this.startDateTarget.value) {
      const utcDate = new Date(this.startDateTarget.value);
      this.utcStartDateTarget.textContent = utcDate.toLocaleString(undefined, dateFormatOptions);
    } else {
      this.utcStartDateTarget.textContent = '-';
    }
  
    // Update UTC end date
    if (this.endDateTarget.value) {
      const utcDate = new Date(this.endDateTarget.value);
      this.utcEndDateTarget.textContent = utcDate.toLocaleString(undefined, dateFormatOptions);
    } else {
      this.utcEndDateTarget.textContent = '-';
    }
  }

  toggleCountdownFields() {
    const isCountdown = this.selectTarget.value == this.countdownValue;
    this.countdownFieldsTarget.classList.toggle('d-none', !isCountdown);

    // Reset inputs if tile type is not countdown
    if (!isCountdown) {
        this.countdownFieldsTarget.querySelectorAll('input').forEach(input => input.value = '');
    }
  }
}