import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="motiv-collection"
export default class extends Controller {
  static targets = [ "selectbox" ]
  static values = { url: String }
  connect() {
    console.log(this.selectboxTarget.id);
    console.log(this.urlValue);
    $("#" + this.selectboxTarget.id).select2({
      width: '100%',
      ajax: {
        url: this.urlValue,
        dataType: 'json',
         
        // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
      }
    });
  }
}
