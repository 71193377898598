import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="field-copy"
export default class extends Controller {
  static targets = ["destination"]


  copy(event) {
    
    
    this.destinationTargets.forEach((destinationTarget) => {
      if (event.target.dataset.copyIdentifier === destinationTarget.dataset.copyIdentifier) {
        destinationTarget.value = event.target.value;
      }
    });
  }

}
