import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-hide"
export default class extends Controller {

  static targets = [ "hideable", "select" ]

  connect() {
    this.selectTargets.forEach((select) => {
      this.showHide({target: select});
    });
  }

  showHide(e) {
    var searchBase = e.target.dataset.autoHideName + "-";
    var searchQuery = searchBase + e.target.value;

    
    this.hideableTargets.forEach((hideable) => {

      if (typeof(hideable.dataset.autoHideName) != "undefined" && hideable.dataset.autoHideName.indexOf(searchBase) === 0) {
        if (hideable.dataset.autoHideName !== searchQuery) {
          hideable.classList.remove("auto-hidden");
        }
        else 
        {
          hideable.classList.add("auto-hidden");
        }
      }

    });
  }
}
