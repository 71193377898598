import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stock-item-amount-manual"
export default class extends Controller {
  static targets = [ "selectbox", "id", "infoField" ]
  static ignore_change = false;


  connect() {
    $(this.selectboxTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    })

    this.show_hide_info_fields();
    
    this.idTarget.focus();
  }
  
  id_changed() {
    console.log(this.idTarget.value);
    
    
    this.selectboxTarget.value = this.idTarget.value;
    this.selectboxTarget.dispatchEvent(new Event("change")); 
    
    
    this.show_hide_info_fields();
    
  }

  selectbox_changed() 
  {
    console.log("selectbox changed");
    if (this.selectboxTarget.value != "")
    {
      console.log("set value");
      this.idTarget.value = this.selectboxTarget.value;
    }

    this.show_hide_info_fields();
  }


  show_hide_info_fields() {

    var id = this.idTarget.value;

    this.infoFieldTargets.forEach((infoField) => {
      if (infoField.dataset.stockItemId == id) {
        infoField.classList.remove("d-none");
      }
      else
      {
        infoField.classList.add("d-none");
      }
    });
  }
}
