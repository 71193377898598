import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="basket"
export default class extends Controller {
  static targets = [ "form" ]
  connect() {
    
  }

  selectChange(event) {
    this.formTarget.submit();
  }

}
