import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["stockItemSelect", "motivSelect", "amountField", "priceField"]
  static values = { url: String, orderId: Number }

  connect() {
    console.log('Storno Price Update Controller active');

    // On stock item change, update price
    $(this.stockItemSelectTarget).on('select2:select', this.updatePrice.bind(this));
  }

  updatePrice() {
    const selectedStockItemId = this.stockItemSelectTarget.value;
    const selectedMotivId = this.motivSelectTarget.value;
    const selectedAmount = this.amountFieldTarget.value;

    if (!selectedMotivId || !selectedStockItemId || !selectedAmount || !this.urlValue || !this.orderIdValue) {
      console.error('Missing required values');
      return;
    }

    // Build url to fetch new price
    const url = `${this.urlValue}?stock_item_id=${selectedStockItemId}`
      + `&motiv_id=${selectedMotivId}`
      + `&amount=${selectedAmount}`
      + `&order_id=${this.orderIdValue}`;

    fetch(url, {
      headers: {
        "Accept": "application/json"
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.price !== undefined && data.price !== null) {
        this.priceFieldTarget.value = data.price;
      }
    })
  }
}