import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="direcktdruck"
export default class extends Controller {
  static targets = [ "barcodeInput", "printButton", "resetButton", "printFrame", "printFrameEnd" ]
  connect() {
    console.log("DirecktdruckController connected")
    console.log(this.printButtonTargets.length);
    console.log(this.resetButtonTargets.length);
    console.log(this.barcodeInputTargets.length);
  }



  printFrameEndTargetConnected() {
    console.log("printFrameEnd connected");

    if (this.printButtonTargets.length <= this.resetButtonTargets.length)
    {
      this.barcodeInputTarget.focus();
    }
  }


}
