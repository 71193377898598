import "trix"
import "@rails/actiontext"
import "@hotwired/turbo-rails"
import "./controllers"
import "jquery/dist/jquery.min"
import "./chart.min.js"




import Rails from '@rails/ujs';
//Rails.start();

import jquery from "jquery";
window.$ = jquery
window.jQuery = jquery

import select2 from 'select2';

//Hook up select2 to jQuery
select2($);



import bootstrap from "./bootstrap.bundle.js"
//import bootstrap from "bootstrap/dist/js/bootstrap.bundle"
window.bootstrap = bootstrap


$(document).on('select2:open', () => {
	let allFound = document.querySelectorAll('.select2-container--open .select2-search__field');
	allFound[allFound.length - 1].focus();
});

document.addEventListener("turbo:load", function() {
	$('.select2-simple').select2({
		theme: "classic", 
		selectionCssClass: "form-select"
	});
});