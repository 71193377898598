import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["days", "hours", "minutes", "seconds", "title"];
  static values = {
    startDate: String,
    endDate: String,
    startTitle: String,
    endTitle: String
  };

  connect() {
    this.lastUpdate = Date.now();
    this.remainingStartSeconds = this.startDateValue;
    this.remainingEndSeconds = this.endDateValue;

    this.startCountdown();
  }

  startCountdown() {
    this.interval = setInterval(() => {
      const now = Date.now();
      const elapsed = now - this.lastUpdate;
      this.lastUpdate = now;

      // Update countdown based on remaining seconds
      if (this.remainingStartSeconds > 0) {
        // First countdown
        this.remainingStartSeconds -= elapsed / 1000;
        this.titleTarget.textContent = this.startTitleValue;
        this.updateCountdown(Math.max(0, this.remainingStartSeconds * 1000));
      } else if (this.remainingEndSeconds > 0) {
        // Second countdown
        this.remainingEndSeconds -= elapsed / 1000;
        this.titleTarget.textContent = this.endTitleValue;
        this.updateCountdown(Math.max(0, this.remainingEndSeconds * 1000));
      } else {
        // End countdown
        this.titleTarget.textContent = this.endTitleValue;
        clearInterval(this.interval);
        this.updateCountdown(0);
      }
    }, 1000);
  }

  // Update UI
  updateCountdown(distance) {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    this.daysTarget.textContent = this.formatNumber(days);
    this.hoursTarget.textContent = this.formatNumber(hours);
    this.minutesTarget.textContent = this.formatNumber(minutes);
    this.secondsTarget.textContent = this.formatNumber(seconds);
  }

  formatNumber(number) {
    return number < 10 ? `0${number}` : number;
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}