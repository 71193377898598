import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password"
export default class extends Controller {

  static targets = [ "password", "toggle" ]

  connect() {
  }

  toggle() {
    if (this.passwordTarget.type === "password") {
      this.passwordTarget.type = "text"
    } else {
      this.passwordTarget.type = "password"
    }
  }
}
