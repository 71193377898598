import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-full-with"
export default class extends Controller {
  static targets = [ "selectbox" ]
  static values = { url: String }

  connect() {
    console.log(this.selectboxTarget.id);
    console.log(this.urlValue);
    $("#" + this.selectboxTarget.id).select2({
      width: '100%'
    });
  }
}
