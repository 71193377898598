
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = {
		data: Array,
		label: String
	}
	static targets = ["canvas"]
	
	connect() {
		
		const ctx = this.canvasTarget.getContext("2d");
		
		
		const myChart = new Chart(ctx, {
				type: 'line',
				data: {
						datasets: [{
							label: this.labelValue,
							data: this.dataValue,
							borderColor: '#0d6efd',
							cubicInterpolationMode: 'monotone',
						}]
						
				},
				options: {
						scales: {
								y: {
										beginAtZero: true
								}
						},
						maintainAspectRatio: false,
				}
		});

		
	}

  
}