import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="countdown"
export default class extends Controller {
  static values = {
    secondsUntilFinish: Number,
    days: String, 
    day: String,
  }
  static targets = [ "countdown" ]

  connect() {
    this.lastCountdownExecute = Math.floor(Date.now() / 1000);
    console.log(this.secondsUntilFinishValue);

    this.countdownInterval = setInterval(() => {
      this.countdown();
    });
  }

  disconnect() {
    this.stopCountdown();
  }

  stopCountdown() {
    clearInterval(this.countdownInterval);
  }

  countdown() {
    
    const timeDiff = Math.floor(Date.now() / 1000) - this.lastCountdownExecute;

    this.secondsUntilFinishValue = this.secondsUntilFinishValue - timeDiff;

    if (this.secondsUntilFinishValue <= 0) {
      this.secondsUntilFinishValue = 0;
      this.stopCountdown();
    }

    const days = Math.floor(this.secondsUntilFinishValue / 86400);
    const hours = Math.floor((this.secondsUntilFinishValue % 86400) / 3600);
    const minutes = Math.floor(((this.secondsUntilFinishValue % 86400) % 3600) / 60);
    const seconds = Math.floor(((this.secondsUntilFinishValue % 86400) % 3600) % 60);

    var dayString = this.daysValue;

    if (days == 1) {
      dayString = this.dayValue;
    }



    this.countdownTarget.innerHTML = `${days} ${dayString} ${this.prependZero(hours)}:${this.prependZero(minutes)}:${this.prependZero(seconds)}`;

    
    this.lastCountdownExecute = Math.floor(Date.now() / 1000);
  }

  prependZero(number) {
    if (number <= 9)
        return "0" + number;
    else
        return number;
 }
}
